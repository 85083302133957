import '@/features/EventManager';
import '@/assets/css/index.css';
import 'tailwindcss/tailwind.css';

import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import * as Sentry from '@sentry/vue';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import flagsmith from 'flagsmith';
import Maska from 'maska';
import { createApp } from 'vue';

import { FLAGSMITH_API, FLAGSMITH_ENV_ID } from '@/config';
import { useAuthMethods } from '@/features/Auth';
import { setupBarcode } from '@/features/Barcode';
import { featureFlags } from '@/features/FeatureFlags';
import { getMillisecondsByTime } from '@/utils/date';

import App from './app/App.vue';
import components from './app/components';
import focus from './directives/focus';
import preserveScrollTop from './directives/preserveScrollTop';
import { i18n } from './locales';
import { baseRoute, routerFactory } from './router';

const { rehydrateAccount } = useAuthMethods();

flagsmith.init({
  environmentID: FLAGSMITH_ENV_ID || '',
  api: FLAGSMITH_API,
  enableAnalytics: true,
  preventFetch: true,
  cacheFlags: true,
  onChange: (oldFlags) => {
    eventManager.emit('flagsmith:change', oldFlags);
  },
});

await rehydrateAccount();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      cacheTime: featureFlags.useRequestsCache
        ? getMillisecondsByTime({ minutes: 5 })
        : 0,
    },
  },
});

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_TOKEN,
  integrations: [
    Sentry.browserTracingIntegration({ router: routerFactory(baseRoute) }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/studio-erp.dev.cluster.kznexpess\.com/,
    /^https:\/\/studio-erp.staging.cluster.kznexpess\.com/,
    /^https:\/\/studio.ktask\.ru/,
  ],
  trackComponents: true,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT_NAME,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(routerFactory(baseRoute));
app.use(i18n);
app.use(components);
app.use(Maska);
app.use(VueQueryPlugin, { queryClient });
app.use(autoAnimatePlugin);
app.directive('preserve-scroll-top', preserveScrollTop);
app.directive('focus', focus);
setupBarcode();

app.mount('#app');
