import { LOCALE } from '@/config';

function prettify(value: number): string {
  return value > 9 ? String(value) : `0${value}`;
}

export function getMillisecondsByTime({
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
}) {
  const totalMilliseconds =
    hours * 3600 * 1000 + // Convert hours to milliseconds
    minutes * 60 * 1000 + // Convert minutes to milliseconds
    seconds * 1000 + // Convert seconds to milliseconds
    milliseconds; // Add any additional milliseconds

  return totalMilliseconds;
}

export function getFullDate(date: Date): string {
  const year = date.getFullYear();
  // Month are counted from 0
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${prettify(day)}.${prettify(month)}.${year}`;
}

export function getFullTime(date: Date, shouldShowSeconds = false): string {
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  if (shouldShowSeconds) {
    return `${prettify(hour)}:${prettify(minute)}:${prettify(second)}`;
  } else {
    return `${prettify(hour)}:${prettify(minute)}`;
  }
}

export const locale = LOCALE || 'ru-RU';
const MS_IN_SECOND = 1000;

export const formatDateTime = (
  value: number,
  options = { dateStyle: 'short', timeStyle: 'short' },
): string =>
  new Date(value * MS_IN_SECOND)
    .toLocaleString(locale, options)
    .replace(',', '');
