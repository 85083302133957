import { reactive } from 'vue';

export const showErrorModalCodes = [
  1016, 1059, 1013, 1010, 1015, 1008, 1026, 1024, 1073,
];

export interface IErrorModalContent {
  title: string;
  text?: string;
  hasReset?: boolean;
}

interface IErrorModalState extends IErrorModalContent {
  value: boolean;
}

export function useErrorModal() {
  const state: IErrorModalState = reactive({
    value: false,
    title: '',
    text: '',
    hasReset: false,
  });

  const resetErrorModal = () => {
    state.value = false;
    state.title = '';
    state.text = '';
    state.hasReset = false;
  };

  const openErrorModal = ({
    title,
    text = '',
    hasReset = false,
  }: IErrorModalContent) => {
    state.value = true;
    state.title = title;
    state.text = text;
    state.hasReset = hasReset;
  };

  return {
    state,
    resetErrorModal,
    openErrorModal,
  };
}
