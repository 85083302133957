/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReservationDetailedWithItems,
    ReservationDetailedWithItemsFromJSON,
    ReservationDetailedWithItemsToJSON,
    ReservationInfoListResponse,
    ReservationInfoListResponseFromJSON,
    ReservationInfoListResponseToJSON,
    ReservationStatus,
    ReservationStatusFromJSON,
    ReservationStatusToJSON,
    ReservationTemplateV2,
    ReservationTemplateV2FromJSON,
    ReservationTemplateV2ToJSON,
    ReservationWithValidateError,
    ReservationWithValidateErrorFromJSON,
    ReservationWithValidateErrorToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
    SortByDate,
    SortByDateFromJSON,
    SortByDateToJSON,
} from '../models';

export interface CreateReservationRequest {
    reservationTemplateV2?: ReservationTemplateV2;
}

export interface GetReservationDetailedRequest {
    reservationID: string;
    page: number;
    size: number;
    sort?: Array<SortByDate>;
}

export interface GetReservationsRequest {
    page: number;
    size: number;
    mine?: boolean;
    reservationStatuses?: Array<ReservationStatus>;
    sort?: Array<SortByDate>;
}

/**
 * 
 */
export class ReservationApi extends runtime.BaseAPI {

    /**
     * Зарезервировать список ску
     */
    async createReservationRaw(requestParameters: CreateReservationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReservationWithValidateError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reservation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReservationTemplateV2ToJSON(requestParameters.reservationTemplateV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationWithValidateErrorFromJSON(jsonValue));
    }

    /**
     * Зарезервировать список ску
     */
    async createReservation(requestParameters: CreateReservationRequest = {}, initOverrides?: RequestInit): Promise<ReservationWithValidateError> {
        const response = await this.createReservationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить полную информацию о резервации
     */
    async getReservationDetailedRaw(requestParameters: GetReservationDetailedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReservationDetailedWithItems>> {
        if (requestParameters.reservationID === null || requestParameters.reservationID === undefined) {
            throw new runtime.RequiredError('reservationID','Required parameter requestParameters.reservationID was null or undefined when calling getReservationDetailed.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getReservationDetailed.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getReservationDetailed.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reservation/{reservationID}`.replace(`{${"reservationID"}}`, encodeURIComponent(String(requestParameters.reservationID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationDetailedWithItemsFromJSON(jsonValue));
    }

    /**
     * Получить полную информацию о резервации
     */
    async getReservationDetailed(requestParameters: GetReservationDetailedRequest, initOverrides?: RequestInit): Promise<ReservationDetailedWithItems> {
        const response = await this.getReservationDetailedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список резерваций
     */
    async getReservationsRaw(requestParameters: GetReservationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReservationInfoListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getReservations.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getReservations.');
        }

        const queryParameters: any = {};

        if (requestParameters.mine !== undefined) {
            queryParameters['mine'] = requestParameters.mine;
        }

        if (requestParameters.reservationStatuses) {
            queryParameters['reservationStatuses'] = requestParameters.reservationStatuses;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reservation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationInfoListResponseFromJSON(jsonValue));
    }

    /**
     * Получить список резерваций
     */
    async getReservations(requestParameters: GetReservationsRequest, initOverrides?: RequestInit): Promise<ReservationInfoListResponse> {
        const response = await this.getReservationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
