<template>
  <ModalWrapper hide-header hide-footer @close="$emit('close')">
    <div class="wrapper" data-test-id="modal__onboarding">
      <div class="top-block">
        <span
          class="title text-xxl font-medium leading-short"
          data-test-id="title"
        >
          {{ $t('greeting.title') }}
        </span>
        <span class="content text-m leading-short">
          {{ $t('greeting.content', { companyName: COMPANY_NAME }) }}
        </span>
        <img :src="GreetingImg" />
      </div>
      <ui-button
        type="primary"
        data-test-id="button__continue"
        @click="handleClose"
      >
        {{ $t('greeting.continue') }}
      </ui-button>
    </div>
  </ModalWrapper>
</template>
<script lang="ts">
import { defineComponent, onUnmounted } from 'vue';
export default defineComponent({ name: 'greeting-modal' });
</script>
<script setup lang="ts">
import GreetingImg from '@/assets/greeting.svg';
import { COMPANY_NAME } from '@/config';
import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import ModalWrapper from '@/features/Modals/ModalWrapper.vue';
import { FIRST_LAUNCH_KEY, localStorageService } from '@/services/localStorage';
import { UiButton } from '@/ui';

interface Emits {
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();
const [showIntro] = useFeatureIntro(EIntroKeys.FIRST_LAUNCH_INTRO);

const handleClose = () => {
  showIntro();
  emit('close');
};

onUnmounted(() => {
  localStorageService.set(FIRST_LAUNCH_KEY, '1');
});
</script>

<style lang="stylus" scoped>


.wrapper
  box-sizing border-box
  width 488px
  height 540px
  background White()
  border-radius 8px
  padding 32px 25px
  display flex
  flex-direction column
  justify-content space-between
  align-items center

.title
  color Gray(DK28)

.content
  color Gray(DK16)
  max-width 360px
  text-align center

.top-block
  display grid
  grid-row-gap 20px
  justify-items center
  align-content space-between
</style>
<style lang="stylus">


.tooltipClass
  background Violet(DK32) !important
  width 350px
  max-width 350px

  .introjs-tooltiptext
    font-family 'Inter', sans-serif
    font-size 14px
    line-height 22px
    padding 10px
    color White()
    text-align left

  .introjs-tooltip-header
    display none

  .introjs-arrow
    border-right-color Violet(DK32) !important

  .introjs-tooltipbuttons
    border-top none

    .introjs-button
      @apply text-m font-medium leading-short text-white bg-violet-6
      padding 9px 12px
      border-radius 4px
      border none
      &:hover
        background-color darken(#6935BD, 5)
      &:active
        transform scale(0.98)
      &:disabled
        cursor default
        pointer-events none
        background-color lighten(#6935BD, 20)
      &:focus
        box-shadow none

      text-shadow none

    .introjs-prevbutton
      @apply text-white
      background-color transparent
      color Gray(DK8)
      &:hover
        background-color Gray(LT48)
        color Gray(DK28)
      &:active
        background-color Gray(LT40)
      &:disabled
        color Gray(LT16)

      &:hover
        @apply text-white bg-transparent

.highlightClass
  box-shadow none !important
</style>
