/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Defect,
    DefectFromJSON,
    DefectToJSON,
    DefectDetailed,
    DefectDetailedFromJSON,
    DefectDetailedToJSON,
    DefectReason,
    DefectReasonFromJSON,
    DefectReasonToJSON,
    DefectsResponse,
    DefectsResponseFromJSON,
    DefectsResponseToJSON,
    Invoice,
    InvoiceFromJSON,
    InvoiceToJSON,
    PrintBarcodes,
    PrintBarcodesFromJSON,
    PrintBarcodesToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
    SkuDetailed,
    SkuDetailedFromJSON,
    SkuDetailedToJSON,
    SkuEventListResponse,
    SkuEventListResponseFromJSON,
    SkuEventListResponseToJSON,
    SkuListResponse,
    SkuListResponseFromJSON,
    SkuListResponseToJSON,
    SkuStatus,
    SkuStatusFromJSON,
    SkuStatusToJSON,
    UpdateMedia,
    UpdateMediaFromJSON,
    UpdateMediaToJSON,
} from '../models';

export interface AddDefectEntryRequest {
    skuID: number;
    defect?: Defect;
}

export interface GetActiveInvociceForSkuRequest {
    isActive: boolean;
    skuID: number;
}

export interface GetDefectsRequest {
    skuID: number;
    page: number;
    size: number;
    isSent?: boolean;
}

export interface GetSkuBarcodesRequest {
    printBarcodes?: PrintBarcodes;
}

export interface GetSkuByIDRequest {
    skuID: number;
}

export interface SkuHistoryRequest {
    skuID: number;
    page: number;
    size: number;
}

export interface SkuListRequest {
    page: number;
    size: number;
    status?: Array<SkuStatus>;
    barcode?: string;
    withCreated?: boolean;
    skuGroupIDs?: Array<number>;
    q?: string;
}

export interface UpdateSkuMediaRequest {
    skuID: number;
    updateMedia?: UpdateMedia;
}

/**
 * 
 */
export class SKUApi extends runtime.BaseAPI {

    /**
     * Пометить товар как бракованный
     */
    async addDefectEntryRaw(requestParameters: AddDefectEntryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DefectDetailed>> {
        if (requestParameters.skuID === null || requestParameters.skuID === undefined) {
            throw new runtime.RequiredError('skuID','Required parameter requestParameters.skuID was null or undefined when calling addDefectEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/skus/{skuID}/defects`.replace(`{${"skuID"}}`, encodeURIComponent(String(requestParameters.skuID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DefectToJSON(requestParameters.defect),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefectDetailedFromJSON(jsonValue));
    }

    /**
     * Пометить товар как бракованный
     */
    async addDefectEntry(requestParameters: AddDefectEntryRequest, initOverrides?: RequestInit): Promise<DefectDetailed> {
        const response = await this.addDefectEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить активную накладную для товара
     */
    async getActiveInvociceForSkuRaw(requestParameters: GetActiveInvociceForSkuRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Invoice>>> {
        if (requestParameters.isActive === null || requestParameters.isActive === undefined) {
            throw new runtime.RequiredError('isActive','Required parameter requestParameters.isActive was null or undefined when calling getActiveInvociceForSku.');
        }

        if (requestParameters.skuID === null || requestParameters.skuID === undefined) {
            throw new runtime.RequiredError('skuID','Required parameter requestParameters.skuID was null or undefined when calling getActiveInvociceForSku.');
        }

        const queryParameters: any = {};

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/skus/{skuID}/invoices`.replace(`{${"skuID"}}`, encodeURIComponent(String(requestParameters.skuID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceFromJSON));
    }

    /**
     * Получить активную накладную для товара
     */
    async getActiveInvociceForSku(requestParameters: GetActiveInvociceForSkuRequest, initOverrides?: RequestInit): Promise<Array<Invoice>> {
        const response = await this.getActiveInvociceForSkuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить все отбраковки в порядке обновления (последние сначала)
     */
    async getDefectsRaw(requestParameters: GetDefectsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DefectsResponse>> {
        if (requestParameters.skuID === null || requestParameters.skuID === undefined) {
            throw new runtime.RequiredError('skuID','Required parameter requestParameters.skuID was null or undefined when calling getDefects.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getDefects.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getDefects.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.isSent !== undefined) {
            queryParameters['isSent'] = requestParameters.isSent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/skus/{skuID}/defects`.replace(`{${"skuID"}}`, encodeURIComponent(String(requestParameters.skuID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefectsResponseFromJSON(jsonValue));
    }

    /**
     * Получить все отбраковки в порядке обновления (последние сначала)
     */
    async getDefects(requestParameters: GetDefectsRequest, initOverrides?: RequestInit): Promise<DefectsResponse> {
        const response = await this.getDefectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Распечатать barcode для товаров
     */
    async getSkuBarcodesRaw(requestParameters: GetSkuBarcodesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/skus/barcodes/print`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrintBarcodesToJSON(requestParameters.printBarcodes),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Распечатать barcode для товаров
     */
    async getSkuBarcodes(requestParameters: GetSkuBarcodesRequest = {}, initOverrides?: RequestInit): Promise<string> {
        const response = await this.getSkuBarcodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить товар по ID товара
     */
    async getSkuByIDRaw(requestParameters: GetSkuByIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SkuDetailed>> {
        if (requestParameters.skuID === null || requestParameters.skuID === undefined) {
            throw new runtime.RequiredError('skuID','Required parameter requestParameters.skuID was null or undefined when calling getSkuByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/skus/{skuID}`.replace(`{${"skuID"}}`, encodeURIComponent(String(requestParameters.skuID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuDetailedFromJSON(jsonValue));
    }

    /**
     * Получить товар по ID товара
     */
    async getSkuByID(requestParameters: GetSkuByIDRequest, initOverrides?: RequestInit): Promise<SkuDetailed> {
        const response = await this.getSkuByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить все причины брака для SKU
     */
    async getSkuDefectReasonsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DefectReason>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/skus/defects/reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DefectReasonFromJSON));
    }

    /**
     * Получить все причины брака для SKU
     */
    async getSkuDefectReasons(initOverrides?: RequestInit): Promise<Array<DefectReason>> {
        const response = await this.getSkuDefectReasonsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Получить историю по товару на фотостудию
     */
    async skuHistoryRaw(requestParameters: SkuHistoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SkuEventListResponse>> {
        if (requestParameters.skuID === null || requestParameters.skuID === undefined) {
            throw new runtime.RequiredError('skuID','Required parameter requestParameters.skuID was null or undefined when calling skuHistory.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling skuHistory.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling skuHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/skus/{skuID}/history`.replace(`{${"skuID"}}`, encodeURIComponent(String(requestParameters.skuID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuEventListResponseFromJSON(jsonValue));
    }

    /**
     * Получить историю по товару на фотостудию
     */
    async skuHistory(requestParameters: SkuHistoryRequest, initOverrides?: RequestInit): Promise<SkuEventListResponse> {
        const response = await this.skuHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async skuListRaw(requestParameters: SkuListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SkuListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling skuList.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling skuList.');
        }

        const queryParameters: any = {};

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.barcode !== undefined) {
            queryParameters['barcode'] = requestParameters.barcode;
        }

        if (requestParameters.withCreated !== undefined) {
            queryParameters['withCreated'] = requestParameters.withCreated;
        }

        if (requestParameters.skuGroupIDs) {
            queryParameters['skuGroupIDs'] = requestParameters.skuGroupIDs;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/skus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuListResponseFromJSON(jsonValue));
    }

    /**
     */
    async skuList(requestParameters: SkuListRequest, initOverrides?: RequestInit): Promise<SkuListResponse> {
        const response = await this.skuListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Редактирование медиа данных
     */
    async updateSkuMediaRaw(requestParameters: UpdateSkuMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.skuID === null || requestParameters.skuID === undefined) {
            throw new runtime.RequiredError('skuID','Required parameter requestParameters.skuID was null or undefined when calling updateSkuMedia.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/skus/{skuID}/media`.replace(`{${"skuID"}}`, encodeURIComponent(String(requestParameters.skuID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMediaToJSON(requestParameters.updateMedia),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Редактирование медиа данных
     */
    async updateSkuMedia(requestParameters: UpdateSkuMediaRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateSkuMediaRaw(requestParameters, initOverrides);
    }

}
